import { RefObject } from "react";
import { Player } from "component-media-player/types";

export function mute(mediaRef: RefObject<HTMLMediaElement>, player: Player) {
  if (player.playerState.muted) {
    player.dispatch({ type: "unmute", payload: { mediaRef } });
  } else {
    player.dispatch({ type: "mute", payload: { mediaRef } });
  }
}
