import { Pause, PlayArrow } from "@material-ui/icons";
import React, { FC, useRef } from "react";
import {
  LinearProgress,
  Paper,
  Slider,
  Typography
} from "utils/material-ui-core";
import {
  handleControlsBarMouseEnter,
  handleControlsBarMouseLeave,
  handleControlsBarTransitionEnd,
  handleFullscreenClick,
  handleMuteClick,
  handlePlayClick,
  handleProgressBarClick,
  handleProgressBarMouseEnter,
  handleProgressBarMouseLeave,
  handleProgressBarMouseMove,
  handleThumbnailTransitionEnd,
  handleVolumeControlsMouseEnter,
  handleVolumeControlsMouseLeave,
  handleVolumeSliderChange,
  handleVolumeSliderTransitionEnd
} from "component-media-player/eventHandlers";
import { MediaPlayerControlsProps } from "component-media-player/types";
import { PlayerButton } from "./PlayerButton";
import * as thumbnails from "./thumbnails";

export const Controls: FC<MediaPlayerControlsProps> = props => {
  const { className, mediaRef, player, ...rest } = props;
  const { playerProps, playerState } = player;
  const { mediaDriver = "hls", controlsType = "custom" } = playerProps;
  const thumbnailRef = useRef<HTMLVideoElement>(null);

  const renderPlayControls = () => (
    <div className="rh-mp__controls__play">
      <PlayerButton
        aria-label={playerState.playing ? "Pause" : "Resume"}
        onClick={handlePlayClick.bind(mediaRef, player, {})}
        widthRatio={0.5}
      >
        {playerState.playing ? <Pause /> : <PlayArrow />}
      </PlayerButton>
    </div>
  );

  const renderTimeDisplayControls = () => (
    <Typography className="rh-mp__controls__timedisplay" variant="body1">
      {playerState.formattedTime}
    </Typography>
  );

  const renderProgressControls = () => {
    const renderThumbnail = () => {
      const Thumbnail = thumbnails[mediaDriver];

      return (
        <div
          className="rh-mp__controls__thumbnail__wrapper"
          data-view-state={player.playerState.misc.thumbnailViewState}
          onTransitionEnd={handleThumbnailTransitionEnd.bind(
            mediaRef,
            player,
            {}
          )}
        >
          <Thumbnail
            ref={thumbnailRef}
            className="rh-mp__controls__thumbnail"
            player={player}
          />
        </div>
      );
    };

    const renderProgress = () => (
      <div className="rh-mp__controls__progress__bar__wrapper">
        <LinearProgress
          className="rh-mp__controls__progress__bar"
          color="secondary"
          value={((playerState.time || 0) / (playerState.duration || 1)) * 100}
          valueBuffer={
            ((playerState.buffered || 0) / (playerState.duration || 1)) * 100
          }
          variant="buffer"
        />
      </div>
    );

    return (
      <div className="rh-mp__controls__progress__root">
        {player.playerProps.mediaType === "video" &&
          rest.showThumbnail &&
          renderThumbnail()}
        {renderProgress()}
        <div
          className="rh-mp__controls__progress__overlay"
          onClick={handleProgressBarClick.bind(mediaRef, player, {})}
          onMouseEnter={handleProgressBarMouseEnter.bind(mediaRef, player, {})}
          onMouseLeave={handleProgressBarMouseLeave.bind(mediaRef, player, {})}
          onMouseMove={handleProgressBarMouseMove.bind(
            mediaRef,
            player,
            thumbnailRef
          )}
        />
      </div>
    );
  };

  const renderVolumeControls = () => (
    <div
      className="rh-mp__controls__volume"
      onMouseEnter={handleVolumeControlsMouseEnter.bind(mediaRef, player, {})}
      onMouseLeave={handleVolumeControlsMouseLeave.bind(mediaRef, player, {})}
    >
      <PlayerButton
        aria-label={playerState.muted ? "Unmute" : "Mute"}
        childrenAsHTML={(() => {
          if (playerState.muted || playerState.volume === 0) {
            return `
              <svg x="0px" y="0px" viewBox="0 0 40 34" enable-background="new 0 0 40 34" focusable="false" style="fill: rgb(255, 255, 255); height: 100%; left: 0px; stroke-width: 0px; top: 0px; width: 100%;"><g style="transform: translateX(3px); transition: transform 100ms ease 0s;"><g><path d="M13.8,14.2c-0.5,0.5-1.4,0.8-2,0.8h-1.6C9.5,15,9,15.5,9,16.2v1.6c0,0.7,0.5,1.2,1.2,1.2h1.6c0.7,0,1.6,0.4,2,0.8l2.3,2.3c0.5,0.5,0.8,0.3,0.8-0.4v-9.6c0-0.7-0.4-0.8-0.8-0.4L13.8,14.2z"></path></g><g><path fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="2" d="M22,11.7c0,0,1.1,2.5,1.1,5s-1.1,5-1.1,5" style="opacity: 0; transition: opacity 100ms ease 0s;"></path><path fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="2" d="M25.8,9.2c0,0,1.7,3.8,1.7,7.5c0,3.7-1.7,7.5-1.7,7.5" style="opacity: 0; transition: opacity 100ms ease 0s;"></path></g><g style="opacity: 1; transition: opacity 100ms ease 0s;"><line fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="1.8102" x1="19.2" y1="15" x2="23.2" y2="19"></line><line fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="1.8102" x1="19.2" y1="19" x2="23.2" y2="15"></line></g></g></svg>
            `;
          }

          if (playerState.volume < 0.25) {
            return `
              <svg x="0px" y="0px" viewBox="0 0 40 34" enable-background="new 0 0 40 34" focusable="false" style="fill: rgb(255, 255, 255); height: 100%; left: 0px; stroke-width: 0px; top: 0px; width: 100%;"><g style="transform: translateX(7px); transition: transform 100ms ease 0s;"><g><path d="M13.8,14.2c-0.5,0.5-1.4,0.8-2,0.8h-1.6C9.5,15,9,15.5,9,16.2v1.6c0,0.7,0.5,1.2,1.2,1.2h1.6c0.7,0,1.6,0.4,2,0.8l2.3,2.3c0.5,0.5,0.8,0.3,0.8-0.4v-9.6c0-0.7-0.4-0.8-0.8-0.4L13.8,14.2z"></path></g><g><path fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="2" d="M22,11.7c0,0,1.1,2.5,1.1,5s-1.1,5-1.1,5" style="opacity: 0; transition: opacity 100ms ease 0s;"></path><path fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="2" d="M25.8,9.2c0,0,1.7,3.8,1.7,7.5c0,3.7-1.7,7.5-1.7,7.5" style="opacity: 0; transition: opacity 100ms ease 0s;"></path></g><g style="opacity: 0; transition: opacity 100ms ease 0s;"><line fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="1.8102" x1="19.2" y1="15" x2="23.2" y2="19"></line><line fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="1.8102" x1="19.2" y1="19" x2="23.2" y2="15"></line></g></g></svg>
            `;
          }

          if (playerState.volume < 0.5) {
            return `
              <svg x="0px" y="0px" viewBox="0 0 40 34" enable-background="new 0 0 40 34" focusable="false" style="fill: rgb(255, 255, 255); height: 100%; left: 0px; stroke-width: 0px; top: 0px; width: 100%;"><g style="transform: translateX(3px); transition: transform 100ms ease 0s;"><g><path d="M13.8,14.2c-0.5,0.5-1.4,0.8-2,0.8h-1.6C9.5,15,9,15.5,9,16.2v1.6c0,0.7,0.5,1.2,1.2,1.2h1.6c0.7,0,1.6,0.4,2,0.8l2.3,2.3c0.5,0.5,0.8,0.3,0.8-0.4v-9.6c0-0.7-0.4-0.8-0.8-0.4L13.8,14.2z"></path></g><g><path fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="2" d="M22,11.7c0,0,1.1,2.5,1.1,5s-1.1,5-1.1,5" style="opacity: 1; transition: opacity 100ms ease 0s;"></path><path fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="2" d="M25.8,9.2c0,0,1.7,3.8,1.7,7.5c0,3.7-1.7,7.5-1.7,7.5" style="opacity: 0; transition: opacity 100ms ease 0s;"></path></g><g style="opacity: 0; transition: opacity 100ms ease 0s;"><line fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="1.8102" x1="19.2" y1="15" x2="23.2" y2="19"></line><line fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="1.8102" x1="19.2" y1="19" x2="23.2" y2="15"></line></g></g></svg>
            `;
          }

          return `
            <svg x="0px" y="0px" viewBox="0 0 40 34" enable-background="new 0 0 40 34" focusable="false" style="fill: rgb(255, 255, 255); height: 100%; left: 0px; stroke-width: 0px; top: 0px; width: 100%;"><g style="transform: translateX(1.25px); transition: transform 100ms ease 0s;"><g><path d="M13.8,14.2c-0.5,0.5-1.4,0.8-2,0.8h-1.6C9.5,15,9,15.5,9,16.2v1.6c0,0.7,0.5,1.2,1.2,1.2h1.6c0.7,0,1.6,0.4,2,0.8l2.3,2.3c0.5,0.5,0.8,0.3,0.8-0.4v-9.6c0-0.7-0.4-0.8-0.8-0.4L13.8,14.2z"></path></g><g><path fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="2" d="M22,11.7c0,0,1.1,2.5,1.1,5s-1.1,5-1.1,5" style="opacity: 1; transition: opacity 100ms ease 0s;"></path><path fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="2" d="M25.8,9.2c0,0,1.7,3.8,1.7,7.5c0,3.7-1.7,7.5-1.7,7.5" style="opacity: 1; transition: opacity 100ms ease 0s;"></path></g><g style="opacity: 0; transition: opacity 100ms ease 0s;"><line fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="1.8102" x1="19.2" y1="15" x2="23.2" y2="19"></line><line fill="none" stroke="#ffffff" stroke-line-cap="round" stroke-miterlimit="10" stroke-width="1.8102" x1="19.2" y1="19" x2="23.2" y2="15"></line></g></g></svg>
          `;
        })()}
        onClick={handleMuteClick.bind(mediaRef, player, {})}
        scaleRatio={1.2}
        widthRatio={1}
      />

      <Paper
        className="rh-mp__controls__volume__slider__wrapper"
        data-view-state={player.playerState.misc.volumeSliderViewState}
        onTransitionEnd={handleVolumeSliderTransitionEnd.bind(
          mediaRef,
          player,
          {}
        )}
      >
        <Slider
          classes={{ root: "rh-mp__controls__volume__slider" }}
          color="secondary"
          max={1}
          min={0}
          onChange={handleVolumeSliderChange.bind(mediaRef, player, {})}
          orientation="vertical"
          step={0.01}
          value={playerState.volume}
          valueLabelDisplay="off"
        />
      </Paper>
    </div>
  );

  const renderSettingsControls = () => <></>;

  const renderFullscreenControls = () => (
    <div className="rh-mp__controls__fullscreen">
      <PlayerButton
        aria-label="Enter full screen"
        childrenAsHTML={`
          <svg x="0px" y="0px" viewBox="0 0 40 34" enable-background="new 0 0 40 34" focusable="false" style="fill: rgb(255, 255, 255); height: 100%; left: 0px; stroke-width: 0px; top: 0px; width: 100%;"><g><g><polyline fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="31.4,12.6 31.4,8.7 25.8,8.7"></polyline><polyline fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="14.7,8.7 9.1,8.7 9.1,12.6"></polyline><polyline fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="25.8,24.8 31.4,24.8 31.4,20.9"></polyline><polyline fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="9.1,20.9 9.1,24.8 14.7,24.8"></polyline></g><rect x="13.7" y="12.3" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" enable-background="new" width="13.3" height="8.9"></rect></g></svg>
        `}
        onClick={handleFullscreenClick.bind(mediaRef, player, {})}
        widthRatio={1}
      />
    </div>
  );

  const renderHeroControls = () => (
    <div
      className="rh-mp__controls__hero__root"
      onClick={handlePlayClick.bind(mediaRef, player, {})}
    ></div>
  );

  const renderBar = () => (
    <div
      className="rh-mp__controls__bar__root"
      data-view-state={
        playerState.misc.autoHide
          ? player.playerState.misc.controlsViewState
          : "visible"
      }
      onMouseEnter={handleControlsBarMouseEnter.bind(mediaRef, player, {})}
      onMouseLeave={handleControlsBarMouseLeave.bind(mediaRef, player, {})}
      onTransitionEnd={handleControlsBarTransitionEnd.bind(
        mediaRef,
        player,
        {}
      )}
    >
      {renderPlayControls()}
      {renderTimeDisplayControls()}
      {renderProgressControls()}
      {renderVolumeControls()}
      {rest.showQualitySelector && renderSettingsControls()}
      {rest.showFullscreenToggle && renderFullscreenControls()}
    </div>
  );

  return (
    <div>
      {controlsType === "simple" && (
        <div
          className={`rh-mp__controls rh-mp__controls__root rh-mp__custom-controls`}
        >
          {renderVolumeControls()}
          {renderPlayControls()}
        </div>
      )}
      {controlsType === "custom" && (
        <div className={`rh-mp__controls rh-mp__controls__root ${className}`}>
          {renderHeroControls()}
          {renderBar()}
        </div>
      )}
    </div>
  );
};

Controls.defaultProps = {
  showFullscreenToggle: true,
  showQualitySelector: true,
  showThumbnail: true,
  visibilityBehavior: "auto_hide"
};

export default Controls;
