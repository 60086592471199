import { MediaPlayerBasicPayload, MediaPlayerReducer } from "../types";

export interface SetVolumePayload extends MediaPlayerBasicPayload {
  level: number;
}

export const setVolume: MediaPlayerReducer<SetVolumePayload> = (
  prevState,
  stateDraft,
  payload
) => {
  const { mediaRef } = payload;

  if (!mediaRef?.current) {
    return stateDraft;
  }

  const { level } = payload;

  mediaRef.current.volume = level;

  stateDraft.volume = level;

  return stateDraft;
};
