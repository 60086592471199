import { SyntheticEvent } from "react";
import { fireMediaEventHandler } from "utils/analytics/fireMediaEventHandler";
import { JumpPayload } from "../reducers";
import { MediaEventHandler } from "../types";
import { updateView } from "./helpers";

export const handleEnded: MediaEventHandler<{}, SyntheticEvent> = function (
  this,
  player,
  details,
  event
) {
  fireMediaEventHandler(this.current, "ended");

  updateView(player, "loader", "hiding");

  if (
    player.playerProps?.whenDonePlaying === "show_poster" &&
    player.playerProps?.showPosterSecondsDelay &&
    !player.playerProps?.loop
  ) {
    player.posterTimer?.start(() => {
      const state = player.getLastKnownState();

      if (!state?.playing) {
        const payload: JumpPayload = {
          mediaRef: this,
          seconds: 0
        };

        player.dispatch({ type: "jump", payload });

        updateView(player, "poster", "showing");
      }
    }, player.playerProps?.showPosterSecondsDelay * 1000);
  }
};
