import {
  MediaPlayerBasicPayload,
  MediaPlayerReducer,
  MediaPlayerState
} from "../types";
import { updateFullscreenState } from "./helpers";

export interface UpdateStatePayload extends MediaPlayerBasicPayload {
  state: Partial<Omit<MediaPlayerState, "misc">>;
}

export const updateState: MediaPlayerReducer<UpdateStatePayload> = (
  prevState,
  stateDraft,
  payload
) => {
  const { state } = payload;

  Object.assign(stateDraft, state);

  updateFullscreenState(stateDraft, payload);

  return stateDraft;
};
