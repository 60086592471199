import Hls from "hls.js";
import debounce from "lodash.debounce";
import React, {
  FC,
  forwardRef,
  MutableRefObject,
  useEffect,
  useRef
} from "react";
import { ThumbnailProps } from "component-media-player/types";

type VideoElement = HTMLVideoElement & {
  hls?: Hls;
};

export const hls: FC<ThumbnailProps<VideoElement>> = forwardRef(
  (props, externalRef) => {
    const internalRef = useRef<VideoElement | null>(null);
    const { className, player } = props;
    const { playerProps } = player;
    const streamUrl = playerProps?.source?.streamUrl;

    useEffect(() => {
      if (!Hls.isSupported()) {
        return;
      }

      if (!streamUrl) {
        return;
      }

      if (!internalRef.current) {
        return;
      }

      const hls = new Hls({
        startLevel: -1,
        xhrSetup: (xhr, url) => {
          // https://stackoverflow.com/questions/40885526/mixed-content-with-hls-js
          xhr.open("GET", url.replace("http://", "https://"));
        }
      });

      internalRef.current.hls = hls;

      hls.on(Hls.Events.FRAG_CHANGED, () => {
        const levels = hls.levels.sort((a, b) =>
          a.bitrate > b.bitrate ? 1 : a.bitrate < b.bitrate ? -1 : 0
        );

        hls.currentLevel = hls.levels.indexOf(levels[0]);
      });

      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(streamUrl);
      });

      hls.attachMedia(internalRef.current);

      return () => {
        hls.destroy();
      };
    }, [internalRef, streamUrl]);

    const handleSeeking = debounce(() => {
      internalRef.current?.hls?.startLoad(
        player.playerState.misc.thumbnailOffset
      );
    }, 200);

    const handleSeeked = () => {
      requestAnimationFrame(() => {
        internalRef.current?.hls?.stopLoad();
      });
    };

    return (
      <video
        ref={el => {
          internalRef.current = el;

          if (typeof externalRef === "function") {
            (externalRef as (instance: VideoElement) => void)(el);
          } else {
            (externalRef as MutableRefObject<VideoElement>).current = el;
          }
        }}
        autoPlay={false}
        className={className}
        controls={false}
        crossOrigin="anonymous"
        data-view-state={"visible"}
        disablePictureInPicture
        loop={false}
        muted
        playsInline
        preload="metadata"
        onSeeking={handleSeeking}
        onSeeked={handleSeeked}
      />
    );
  }
);
