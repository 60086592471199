import { MediaPlayerReducer } from "../types";

export const updateDuration: MediaPlayerReducer = (
  prevState,
  stateDraft,
  payload
) => {
  const { mediaRef } = payload;

  const time = mediaRef?.current?.currentTime ?? 0;

  const duration = mediaRef?.current?.duration ?? time ?? 0;

  stateDraft.duration = duration;

  return stateDraft;
};
