import { SyntheticEvent } from "react";
import { MediaEventHandler } from "../types";
import { updateView } from "./helpers";

export const handleThumbnailTransitionEnd: MediaEventHandler<
  {},
  SyntheticEvent
> = function (this, player, details, event) {
  if (Object.is(event.target, event.currentTarget)) {
    if (player.playerState.misc.thumbnailViewState === "showing") {
      updateView(player, "thumbnail", "visible");
    }

    if (player.playerState.misc.thumbnailViewState === "hiding") {
      updateView(player, "thumbnail", "hidden");
    }
  }
};
