import { MouseEvent } from "react";
import { MediaEventHandler } from "../types";
import { fullscreen, play } from "./helpers";

export const handlePlayClick: MediaEventHandler<{}, MouseEvent> = function (
  this,
  player,
  details,
  event
) {
  if (event.detail === 1) {
    play(this, player, event);
  } else {
    fullscreen(this, player);
  }
};
