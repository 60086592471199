import { SyntheticEvent } from "react";
import { UpdateMiscPayload } from "../reducers";
import { MediaEventHandler, MediaPlayerMiscState } from "../types";

export const handleControlsBarMouseEnter: MediaEventHandler<
  {},
  SyntheticEvent
> = function (this, player) {
  player.hideControlsTimer?.reset();

  const misc: Partial<MediaPlayerMiscState> = { controlsHovered: true };

  const payload: UpdateMiscPayload = { misc };

  player.dispatch({ type: "updateMisc", payload });
};
