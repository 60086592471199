import { UpdateMiscPayload } from "component-media-player/reducers";
import {
  MediaPlayerMiscState,
  MediaPlayerViewState,
  Player,
  View
} from "component-media-player/types";

const viewStateKeys: Record<View, keyof MediaPlayerMiscState> = {
  controls: "controlsViewState",
  loader: "loaderViewState",
  poster: "posterViewState",
  thumbnail: "thumbnailViewState",
  volumeSlider: "volumeSliderViewState"
};

export function updateView(
  player: Player,
  view: View,
  newViewState: MediaPlayerViewState
) {
  const currentViewState = player.playerState.misc[viewStateKeys[view]];

  if (currentViewState !== newViewState) {
    const misc: Partial<MediaPlayerMiscState> = {
      [viewStateKeys[view]]: newViewState
    };

    const payload: UpdateMiscPayload = { misc };

    player.dispatch({ type: "updateMisc", payload });
  }
}
