import { MouseEvent } from "react";
import { fireMediaEventHandler } from "utils/analytics/fireMediaEventHandler";
import { JumpPayload } from "../reducers";
import { MediaEventHandler } from "../types";

export const handleProgressBarClick: MediaEventHandler<{}, MouseEvent> =
  function (this, player, details, event) {
    if (!player.playerState) {
      return;
    }

    const eventTarget = event.nativeEvent.target as HTMLElement;

    let offset = event.nativeEvent.offsetX / eventTarget?.offsetWidth;

    if (offset < 0.005) {
      offset = 0;
    }

    const seconds = player.playerState.duration * offset;

    const payload: JumpPayload = {
      mediaRef: this,
      seconds
    };

    player.dispatch({ type: "jump", payload });

    fireMediaEventHandler(this.current, "userJump", { currentTime: seconds });
  };
