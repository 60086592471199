export type ReactPropsMatcher<Props> = (props: Maybe<Props>) => boolean;

/**
 * This function returns the props of the closest react element given any element under its DOM ramification.
 */
export function getClosestReactProps<Props>(
  element: Element | null | undefined,
  predicate: ReactPropsMatcher<Props>,
  fallbackProps?: Props
): Props | undefined {
  while (element) {
    const key = Object.keys(element).find(key =>
      key.startsWith("__reactFiber$")
    );

    const fiber = element[key] as any;

    const props = fiber?.return?.memoizedProps;

    if (typeof props === "object" && predicate(props)) {
      return props;
    }

    element = element.parentElement;
  }

  return fallbackProps;
}
