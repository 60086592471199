import { SyntheticEvent } from "react";
import { MediaEventHandler } from "../types";
import { updateView } from "./helpers";
import { handleHideControls } from "./index";

export const handleShowControls: MediaEventHandler<{}, SyntheticEvent> =
  function (this, player, details, event) {
    if (player.playerState.misc.controlsViewState !== "visible") {
      updateView(player, "controls", "showing");
    }

    handleHideControls.call(this, player, details, event);
  };
