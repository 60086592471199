import { RefObject } from "react";
import { Player } from "component-media-player/types";

export function fullscreen(
  mediaRef: RefObject<HTMLMediaElement>,
  player: Player
) {
  if (player.playerState.fullscreen) {
    player.dispatch({ type: "exitFullscreen", payload: { mediaRef } });
  } else {
    player.dispatch({ type: "requestFullscreen", payload: { mediaRef } });
  }
}
