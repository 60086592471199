import { SyntheticEvent } from "react";
import { UpdateMiscPayload } from "../reducers";
import { MediaEventHandler, MediaPlayerMiscState } from "../types";
import { handleHideControls } from "./index";

export const handleControlsBarMouseLeave: MediaEventHandler<
  {},
  SyntheticEvent
> = function (this, player, details, event) {
  const misc: Partial<MediaPlayerMiscState> = { controlsHovered: false };

  const payload: UpdateMiscPayload = { misc };

  player.dispatch({ type: "updateMisc", payload });

  handleHideControls.call(this, player, details, event);
};
