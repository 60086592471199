import { usePollingWithDeps } from "hooks/usePollingWithDeps";
import { RefObject } from "react";
import { isElementInViewport } from "utils/isElementInViewport";
import { processEnvServer } from "hooks/useSsrHooks";
const TIMEOUT = 30000;

const log =
  !processEnvServer && Boolean(sessionStorage.getItem("PF_DEBUG_SITES_1508"))
    ? (...args) => console?.log?.("PF_DEBUG_SITES_1508", ...args)
    : () => {};

export function useAutoplayEnforcer(
  mediaRef: RefObject<HTMLMediaElement>,
  win = window
): void {
  usePollingWithDeps(
    () => {
      const media = mediaRef.current;

      if (!(typeof media === "object" && media !== null)) {
        // maybe the react component is not yet mounted
        // keep polling

        log("not mounted");

        return false;
      }

      if (!media?.autoplay || !media?.muted) {
        // modern browsers only accept brute force if muted
        // stop polling since there is no way to achieve the goal

        log("not possible");

        return true;
      }

      if (media?.paused) {
        // force play and keep polling until the media is actually playing

        if (isElementInViewport(media, win)) {
          media.play();

          log("trying to play");
        } else {
          log("not in viewport");
        }

        return false;
      }

      // the media is playing - stop polling

      log("done");

      return true;
    },
    [
      mediaRef,
      mediaRef?.current?.getBoundingClientRect()?.left,
      mediaRef?.current?.getBoundingClientRect()?.top,
      win,
      win.innerHeight,
      win.innerWidth
    ],
    {
      timeout: TIMEOUT
    }
  );

  log("restarting polling at", mediaRef?.current?.getBoundingClientRect());
}
