import { SyntheticEvent } from "react";
import { UpdateStatePayload } from "../reducers";
import { MediaEventHandler } from "../types";
import { updateView } from "./helpers";

export const handlePlaying: MediaEventHandler<{}, SyntheticEvent> = function (
  this,
  player,
  details,
  event
) {
  player.posterTimer?.reset();

  if (player?.playerState.misc.loaderViewState !== "hidden") {
    updateView(player, "loader", "hiding");
  }

  if (player?.playerState.misc.posterViewState !== "hidden") {
    updateView(player, "poster", "hiding");
  }

  if (player?.playerState.playing) {
    return;
  }

  const payload: UpdateStatePayload = {
    state: {
      playing: true
    }
  };

  player.dispatch({ type: "updateState", payload });
};
