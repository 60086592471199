import { SyntheticEvent } from "react";
import { MediaEventHandler } from "../types";
import { updateView } from "./helpers";

export const handleHideControls: MediaEventHandler<{}, SyntheticEvent> =
  function (this, player, details, event) {
    player.hideControlsTimer?.reset();

    if (
      player.playerState.misc.controlsViewState !== "hidden" &&
      player.playerState.misc.autoHide
    ) {
      player.hideControlsTimer?.restart(() => {
        const state = player.getLastKnownState();

        if (!state?.misc.controlsHovered) {
          updateView(player, "controls", "hiding");
        }
      });
    }
  };
