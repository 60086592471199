import { SyntheticEvent } from "react";
import { MediaEventHandler } from "../types";
import { updateView } from "./helpers";

export const handleVolumeSliderTransitionEnd: MediaEventHandler<
  {},
  SyntheticEvent
> = function (this, player, details, event) {
  if (Object.is(event.target, event.currentTarget)) {
    if (player.playerState.misc.volumeSliderViewState === "showing") {
      updateView(player, "volumeSlider", "visible");
    }

    if (player.playerState.misc.volumeSliderViewState === "hiding") {
      updateView(player, "volumeSlider", "hidden");
    }
  }
};
