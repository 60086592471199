import React, { FC } from "react";
import { MediaPlayer } from "./components/MediaPlayer";
import { MediaPlayerProps } from "./types";

export type AEMMediaPlayerProps = MediaPlayerProps;

export const AEMMediaPlayer: FC<AEMMediaPlayerProps> = props => {
  return <MediaPlayer {...props} />;
};

AEMMediaPlayer.defaultProps = {
  ...MediaPlayer.defaultProps
};

export default AEMMediaPlayer;
