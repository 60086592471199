import { MediaPlayerReducer } from "../types";
import { vendorFunction } from "../utils";

export const requestFullscreen: MediaPlayerReducer = (
  prevState,
  stateDraft,
  payload
) => {
  const { mediaRef } = payload;

  if (!mediaRef?.current) {
    return stateDraft;
  }

  try {
    vendorFunction(
      mediaRef.current?.closest(".rh-mp__root"),
      "requestFullscreen"
    )?.();

    stateDraft.fullscreen = true;
  } catch (error) {
    console.warn("requestFullscreen:", error);
  }

  return stateDraft;
};
