import { RefObject } from "react";
import { MediaPlayerBasicPayload, MediaPlayerReducer } from "../types";
import { setMediaCurrentTime } from "../utils";

export interface PreviewPayload extends MediaPlayerBasicPayload {
  thumbnailRef: RefObject<HTMLMediaElement>;
  thumbnailTime: number;
}

export const preview: MediaPlayerReducer<PreviewPayload> = (
  prevState,
  stateDraft,
  payload
) => {
  const { thumbnailRef, thumbnailTime } = payload;

  stateDraft.misc.thumbnailTime = thumbnailTime;

  requestAnimationFrame(() => {
    setMediaCurrentTime(thumbnailRef.current, thumbnailTime);
  });

  return stateDraft;
};
