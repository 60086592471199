import throttle from "lodash.throttle";
import { RefObject } from "react";
import { PreviewPayload } from "component-media-player/reducers";
import { Player } from "component-media-player/types";

/**
 * When the user moves the mouse fast over the progress bar,
 * we show the preview at specific/fixed points ( windows ),
 * to take advantage of caching.
 */
export const WINDOW_LENGTH = 30;

export const preview = throttle(
  (
    player: Player,
    thumbnailRef: RefObject<HTMLMediaElement>,
    time: number,
    mode: "absolute" | "windowed" = "absolute"
  ) => {
    const baseTime = time ?? player.playerState.misc.thumbnailTime ?? 0;

    let thumbnailTime = baseTime;

    if (mode === "windowed") {
      const x = WINDOW_LENGTH;

      thumbnailTime = Math.floor(baseTime / x) * x + x / 2;
    }

    const payload: PreviewPayload = {
      thumbnailRef,
      thumbnailTime
    };

    player.dispatch({ type: "preview", payload });
  },
  1000 / 3,
  { trailing: true }
);
