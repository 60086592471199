import { EmitStrategy } from "./types";

type EmitIfConfig = {
  /**
   * Use this to feed more data into the context before the condition check.
   */
  beforeTest?: () => void;

  /**
   * Condition to decide to emit or not.
   */
  condition: () => boolean;

  /**
   * Callback called right before emit.
   */
  beforeEmit?: () => void;

  /**
   * The emit callback in case the test returns true.
   */
  emit?: () => void;

  /**
   * Callback called right after emit.
   */
  afterEmit?: () => void;
};

export const emitIf: EmitStrategy<EmitIfConfig> = ({
  beforeTest,
  condition,
  beforeEmit,
  emit,
  afterEmit
}) => {
  beforeTest?.();

  if (condition()) {
    beforeEmit?.();

    emit?.();

    afterEmit?.();
  }
};
