import { RefObject, SyntheticEvent } from "react";
import { fireMediaEventHandler } from "utils/analytics/fireMediaEventHandler";
import { Player } from "component-media-player/types";

export function play(
  mediaRef: RefObject<HTMLMediaElement>,
  player: Player,
  event: SyntheticEvent
) {
  if (player.playerState.playing) {
    player.dispatch({ type: "pause", payload: { mediaRef } });

    fireMediaEventHandler(mediaRef.current, "userPause");
  } else {
    player.dispatch({ type: "play", payload: { mediaRef } });

    fireMediaEventHandler(mediaRef.current, "userPlay");
  }
}
