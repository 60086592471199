import Hls from "hls.js";
import React, {
  FC,
  forwardRef,
  MutableRefObject,
  useEffect,
  useRef,
  useState
} from "react";
import { useMediaQuery, useTheme } from "utils/material-ui-core";
import {
  handleDurationChange,
  handleEnded,
  handleError,
  handlePause,
  handlePlaying,
  handleProgress,
  handleSeeked,
  handleSeeking,
  handleStalled,
  handleSuspend,
  handleTimeUpdate,
  handleVolumeChange,
  handleWaiting
} from "component-media-player/eventHandlers";
import { useAutoplayEnforcer } from "component-media-player/hooks";
import { MediaProps } from "component-media-player/types";

export const hls: FC<MediaProps<HTMLVideoElement>> = forwardRef(
  (props, externalRef) => {
    const internalRef = useRef<HTMLVideoElement | null>(null);
    const { player } = props;
    const { playerProps } = player;
    const streamUrl = playerProps?.source?.streamUrl;
    const [viewPortStreamUrls, setViewPortStreamUrls] = useState({
      mobile: null,
      tablet: null,
      "medium-desktop": null,
      "large-desktop": null,
      "xl-desktop": null
    });
    const [videoStreamUrl, setVideoStreamUrl] = useState(streamUrl);

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.between(0, 768));
    const sm = useMediaQuery(theme.breakpoints.between(768, 992));
    const md = useMediaQuery(theme.breakpoints.between(992, 1200));
    const lg = useMediaQuery(theme.breakpoints.between(1200, 1366));
    const lg_xl = useMediaQuery(theme.breakpoints.between(1366, 1600));
    const xl = useMediaQuery(theme.breakpoints.up(1600));

    const updateMediaUrl = () => {
      const viewportMapping = playerProps?.viewportMapping || {};
      if (viewportMapping) {
        let viewPortValues = {
          mobile: null,
          tablet: null,
          "medium-desktop": null,
          "large-desktop": null,
          "gary-breakpoint": null,
          "xl-desktop": null
        };
        const keys = Object.keys(viewportMapping);
        for (let i = 0; i < keys.length; i++) {
          const obj = viewportMapping[keys[i]];
          if (obj && typeof obj === "object" && obj.viewport) {
            viewPortValues[obj.viewport] = obj.viewportStreamUrl;
          }
        }
        setViewPortStreamUrls({
          ...viewPortValues
        });
        updateDisplayStreamUrl();
      }
    };

    const updateDisplayStreamUrl = () => {
      let updatedURL = streamUrl;
      if (xs) {
        updatedURL = viewPortStreamUrls?.mobile || streamUrl;
      } else if (sm) {
        updatedURL = viewPortStreamUrls?.tablet || streamUrl;
      } else if (md) {
        updatedURL = viewPortStreamUrls?.["medium-desktop"] || streamUrl;
      } else if (lg) {
        updatedURL = viewPortStreamUrls?.["large-desktop"] || streamUrl;
      } else if (lg_xl) {
        updatedURL = viewPortStreamUrls?.["gary-breakpoint"] || streamUrl;
      } else if (xl) {
        updatedURL = viewPortStreamUrls?.["xl-desktop"] || streamUrl;
      }
      if (updatedURL !== videoStreamUrl) {
        setVideoStreamUrl(updatedURL);
      }
    };

    useEffect(() => {
      updateMediaUrl();
    }, []);

    useEffect(() => {
      updateDisplayStreamUrl();
    }, [xs, sm, md, lg, lg_xl, xl, viewPortStreamUrls]);

    useEffect(() => {
      if (!Hls.isSupported()) {
        return;
      }

      if (!videoStreamUrl) {
        return;
      }

      if (!internalRef.current) {
        return;
      }

      const hls = new Hls({
        xhrSetup: (xhr, url) => {
          // https://stackoverflow.com/questions/40885526/mixed-content-with-hls-js
          xhr.open("GET", url.replace("http://", "https://"));
        }
      });

      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(videoStreamUrl);
      });

      hls.attachMedia(internalRef.current);

      return () => {
        hls.destroy();
      };
    }, [internalRef, videoStreamUrl]);

    useAutoplayEnforcer(internalRef);

    const isShowingPoster = ["showing", "visible"].includes(
      props.player.playerState.misc.posterViewState
    );

    return (
      <video
        ref={el => {
          internalRef.current = el;

          if (typeof externalRef === "function") {
            (externalRef as (instance: HTMLVideoElement) => void)(el);
          } else {
            (externalRef as MutableRefObject<HTMLVideoElement>).current = el;
          }
        }}
        autoPlay={
          !/^\/editor\.html/.test(window.parent?.location?.pathname) &&
          playerProps?.autoplay
        }
        className="rh-mp__video__root"
        controls={playerProps?.controlsType === "native"}
        crossOrigin="anonymous"
        data-view-state={isShowingPoster ? "hidden" : "visible"}
        loop={playerProps?.loop}
        muted={playerProps?.muted}
        playsInline
        onAbort={handleError.bind(internalRef, player, {})}
        onDurationChange={handleDurationChange.bind(internalRef, player, {})}
        onEmptied={handleError.bind(internalRef, player, {})}
        onEnded={handleEnded.bind(internalRef, player, {})}
        onError={handleError.bind(internalRef, player, {})}
        onLoadedData={handleProgress.bind(internalRef, player, {})}
        onPause={handlePause.bind(internalRef, player, {})}
        onPlaying={handlePlaying.bind(internalRef, player, {})}
        onProgress={handleProgress.bind(internalRef, player, {})}
        onSeeked={handleSeeked.bind(internalRef, player, {})}
        onSeeking={handleSeeking.bind(internalRef, player, {})}
        onStalled={handleStalled.bind(internalRef, player, {})}
        onSuspend={handleSuspend.bind(internalRef, player, {})}
        onTimeUpdate={handleTimeUpdate.bind(internalRef, player, {})}
        onVolumeChange={handleVolumeChange.bind(internalRef, player, {})}
        onWaiting={handleWaiting.bind(internalRef, player, {})}
      >
        <source src={videoStreamUrl} type="video/mp4" />
      </video>
    );
  }
);
