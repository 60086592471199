import { produce } from "immer";
import { useEffect, useReducer, useRef } from "react";
import * as reducers from "../reducers";
import {
  MediaPlayerAction,
  MediaPlayerProps,
  MediaPlayerState,
  Player
} from "../types";
import { newEmptyState } from "../utils";
import { useTimer } from "./useTimer";

const CONTROLS_HIDE_TIMEOUT = 3000;

export function usePlayer(playerProps: MediaPlayerProps): Readonly<Player> {
  const { ControlsProps, muted, poster } = playerProps;

  const autoHide = ControlsProps?.visibilityBehavior === "auto_hide";

  const [playerState, dispatch] = useReducer(
    (prevState: MediaPlayerState, action: MediaPlayerAction<any>) => {
      return produce(prevState, stateDraft => {
        const { type, payload } = action;

        const newState = reducers?.[type]?.(prevState, stateDraft, payload);

        return newState;
      });
    },
    newEmptyState(
      {
        muted
      },
      {
        autoHide,
        posterViewState: poster?.url ? "visible" : "hidden"
      }
    )
  );

  const lastKnownStateRef = useRef<MediaPlayerState>(playerState);

  useEffect(() => {
    lastKnownStateRef.current = playerState;
  }, [playerState]);

  const getLastKnownState = () => lastKnownStateRef.current;

  const hideControlsTimer = useTimer(CONTROLS_HIDE_TIMEOUT);

  const posterTimer = useTimer();

  return {
    dispatch: (value: MediaPlayerAction<any>): void => {
      dispatch(value);
    },
    getLastKnownState,
    hideControlsTimer,
    playerProps,
    playerState,
    posterTimer
  };
}
