import { SyntheticEvent } from "react";
import { MediaEventHandler } from "../types";

export const handleDurationChange: MediaEventHandler<{}, SyntheticEvent> =
  function (this, player) {
    if (player.playerProps.controlsType !== "custom") {
      // There is no need to update the duration if the controls are not custom.
      // To save some rendering time.
      return;
    }

    player?.dispatch({
      type: "updateDuration",
      payload: {
        mediaRef: this
      }
    });
  };
