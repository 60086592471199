import { SyntheticEvent } from "react";
import { MediaEventHandler } from "../types";
import { updateView } from "./helpers";

export const handleVolumeControlsMouseLeave: MediaEventHandler<
  {},
  SyntheticEvent
> = function (this, player, details, event) {
  updateView(player, "volumeSlider", "hiding");
};
