import { UpdateMiscPayload } from "component-media-player/reducers";
import {
  MediaPlayerMiscState,
  MediaPlayerViewState,
  Player
} from "component-media-player/types";
import { updateView } from "./updateView";

export function updateThumbnail(
  player: Player,
  viewState: MediaPlayerViewState,
  thumbnailOffset: number | undefined
) {
  const misc: Partial<MediaPlayerMiscState> = {
    thumbnailOffset:
      thumbnailOffset ?? player.playerState.misc.thumbnailOffset ?? 0
  };

  const payload: UpdateMiscPayload = { misc };

  player.dispatch({ type: "updateMisc", payload });

  updateView(player, "thumbnail", viewState);
}
