import { SyntheticEvent } from "react";
import { MediaEventHandler } from "../types";
import { updateView } from "./helpers";
import { handleShowControls } from "./index";

export const handleMouseMove: MediaEventHandler<{}, SyntheticEvent> = function (
  this,
  player,
  details,
  event
) {
  if (player.playerState.misc.controlsHovered) {
    updateView(player, "controls", "visible");
  } else {
    handleShowControls.call(this, player, details, event);
  }
};
