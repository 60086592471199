import { SyntheticEvent } from "react";
import { MediaEventHandler } from "../types";
import { updateView } from "./helpers";

export const handleWaiting: MediaEventHandler<{}, SyntheticEvent> = function (
  this,
  player,
  details,
  event
) {
  if (player.playerProps.controlsType !== "custom") {
    // There is no need to show the loader if the controls are not custom.
    // To save some rendering time.
    return;
  }

  updateView(player, "loader", "showing");
};
