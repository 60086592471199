import { useCallback, useRef, useState } from "react";

export function usePollingWithDeps(
  poll: () => any,
  deps: ReadonlyArray<any>,
  { interval = 1000, timeout = 15000, win = window } = {}
): boolean {
  if (typeof win.requestAnimationFrame !== "function") {
    return false;
  }

  const [isSatisfied, setIsSatisfied] = useState(false);

  const startTime = useRef(-1);

  const lastCheckTime = useRef(0);

  const check = useCallback(
    (timestamp: number) => {
      if (isSatisfied) {
        return;
      }

      if (startTime.current === -1) {
        startTime.current = timestamp;
      }

      const elapsedSinceStart = timestamp - startTime.current;

      if (elapsedSinceStart >= timeout) {
        return;
      }

      const elapsedSinceLastCheck = timestamp - lastCheckTime.current;

      if (elapsedSinceLastCheck < interval) {
        win.requestAnimationFrame(check);

        return;
      }

      lastCheckTime.current = timestamp;

      if (!!poll?.()) {
        setIsSatisfied(true);

        return;
      }

      win.requestAnimationFrame(check);
    },
    [deps, interval, poll, timeout, win]
  );

  if (!isSatisfied) {
    win.requestAnimationFrame(check);
  }

  return isSatisfied;
}
