import { SyntheticEvent } from "react";
import { UpdateStatePayload } from "../reducers";
import { MediaEventHandler } from "../types";

export const handleVolumeChange: MediaEventHandler<{}, SyntheticEvent> =
  function (this, player, details, event) {
    if (!this.current || typeof this.current.volume === "number") {
      return;
    }

    const payload: UpdateStatePayload = {
      state: {
        volume: this.current.volume
      }
    };

    player.dispatch({ type: "updateState", payload });
  };
