export function setMediaCurrentTime(
  media: HTMLMediaElement | null | undefined,
  currentTime: number | null | undefined
) {
  if (media && typeof currentTime === "number" && !isNaN(currentTime)) {
    try {
      media.currentTime = currentTime;
    } catch (err) {
      console.warn("Error trying to set current time:", err);
    }
  }
}
