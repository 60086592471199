import { MediaPlayerReducer } from "../types";
import { updateFullscreenState } from "./helpers";

export const updateTime: MediaPlayerReducer = (
  prevState,
  stateDraft,
  payload
) => {
  const { mediaRef } = payload;

  const time = mediaRef?.current?.currentTime ?? 0;

  const mins = Math.trunc(time / 60);

  const secs = Math.trunc(time % 60).toString();

  const formattedTime = `${mins}:${secs.length === 1 ? `0${secs}` : secs}`;

  stateDraft.time = time;

  stateDraft.formattedTime = formattedTime;

  updateFullscreenState(stateDraft, payload);

  return stateDraft;
};
