import { SyntheticEvent } from "react";
import { UpdateMiscPayload } from "../reducers";
import { MediaEventHandler, MediaPlayerMiscState } from "../types";

export const handleProgressBarMouseEnter: MediaEventHandler<
  {},
  SyntheticEvent
> = function (this, player, details) {
  const misc: Partial<MediaPlayerMiscState> = { progressBarHovered: true };

  const payload: UpdateMiscPayload = { misc };

  player.dispatch({ type: "updateMisc", payload });
};
