import { SyntheticEvent } from "react";
import { MediaEventHandler } from "../types";
import { updateView } from "./helpers";

export const handleSeeked: MediaEventHandler<{}, SyntheticEvent> = function (
  this,
  player,
  details,
  event
) {
  updateView(player, "loader", "hiding");
};
