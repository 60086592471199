import { SyntheticEvent } from "react";
import { UpdateMiscPayload } from "../reducers";
import { MediaEventHandler, MediaPlayerMiscState } from "../types";
import { updateThumbnail } from "./helpers";

export const handleProgressBarMouseLeave: MediaEventHandler<
  {},
  SyntheticEvent
> = function (this, player, details, event) {
  const misc: Partial<MediaPlayerMiscState> = { progressBarHovered: false };

  const payload: UpdateMiscPayload = { misc };

  player.dispatch({ type: "updateMisc", payload });

  updateThumbnail(player, "hiding", undefined);
};
