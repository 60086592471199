type EmitWithContextOptions<Context, Subject> = {
  /**
   * The main subject. It is used as the key when creating new a context.
   */
  subject: Subject | null | undefined;

  /**
   * A factory function to create a new context per subject.
   *
   * Contexts are unique per subject and across calls.
   *
   * Contexts are held in memory only and are lost when the page unloads. If you need to keep the context
   * across loads you should do it on your context factory function.
   */
  contextFactory?: (subject: Subject | null | undefined) => Context;

  callback: (callback: Context) => void;
};

const contextMap = new WeakMap<any, any>();

export function emitWithContext<Context, Subject>({
  subject,
  contextFactory,
  callback
}: EmitWithContextOptions<Context, Subject>): void {
  if (!subject) {
    return;
  }

  let context = contextMap.get(subject);

  if (!context) {
    context = contextFactory?.(subject);

    if (context) {
      contextMap.set(subject, context);
    }
  }

  if (!context) {
    return;
  }

  callback(context);
}
