import { ChangeEvent } from "react";
import { SetVolumePayload } from "../reducers";
import { MediaEventHandler } from "../types";

export const handleVolumeSliderChange: MediaEventHandler<
  {},
  ChangeEvent<{}>,
  number | number[]
> = function (this, player, details, event, level) {
  const payload: SetVolumePayload = {
    mediaRef: this,
    level: level as number
  };

  player.dispatch({ type: "setVolume", payload });
};
