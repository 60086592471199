import { useRef } from "react";
import { Timer, TimerReset, TimerStart } from "../types";

const noAlarm = function () {};

export function useTimer(fixedTime?: number, fixedAlarm?: Function): Timer {
  const timer = useRef(0);

  const isDone = () => timer.current === 0;

  const start: TimerStart = (alarm, time) => {
    if (timer.current) {
      return;
    }

    reset();

    const fn = fixedAlarm ?? alarm ?? noAlarm;

    const timeout = fixedTime ?? time ?? 0;

    if (fn) {
      timer.current = window.setTimeout(() => {
        reset();

        fn();
      }, timeout);
    }
  };

  const restart: TimerStart = (alarm, time) => {
    reset();

    start(alarm, time);
  };

  const reset: TimerReset = () => {
    window.clearTimeout(timer.current);

    timer.current = 0;
  };

  return {
    isDone,
    reset,
    restart,
    start
  };
}
