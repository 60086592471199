import throttle from "lodash.throttle";
import { MouseEvent, RefObject } from "react";
import { MediaEventHandler, Player } from "../types";
import { preview, updateThumbnail } from "./helpers";

const throttled = throttle(
  (
    player: Player,
    thumbnailRef: RefObject<HTMLMediaElement>,
    offset: number,
    seconds: number,
    fast: boolean
  ) => {
    updateThumbnail(player, "showing", offset);

    preview(player, thumbnailRef, seconds, fast ? "windowed" : "absolute");
  },
  1000 / 30,
  { leading: true }
);

export const handleProgressBarMouseMove: MediaEventHandler<
  RefObject<HTMLMediaElement>,
  MouseEvent
> = function (this, player, thumbnailRef, event) {
  if (!player.playerState.misc.progressBarHovered) {
    return;
  }

  if (!event.nativeEvent) {
    return;
  }

  if (!event.nativeEvent.target) {
    return;
  }

  const target = event.nativeEvent.target as HTMLElement;

  const offset = event.nativeEvent.offsetX / target.offsetWidth;

  const seconds = player.playerState.duration * offset;

  const fast = Math.abs(event.nativeEvent.movementX) > 10;

  throttled(player, thumbnailRef, offset, seconds, fast);
};
