import { SyntheticEvent } from "react";
import { UpdateStatePayload } from "../reducers";
import { MediaEventHandler } from "../types";

export const handlePause: MediaEventHandler<{}, SyntheticEvent> = function (
  this,
  player,
  details
) {
  if (!player?.playerState.playing) {
    return;
  }

  const payload: UpdateStatePayload = {
    state: {
      playing: false
    }
  };

  player.dispatch({ type: "updateState", payload });
};
