import React, { FC, RefObject } from "react";
import memoize from "utils/memoize";
import { MediaPlayerPoster, Player } from "component-media-player/types";

type PosterFC = FC<{
  className: string;
  mediaRef: RefObject<HTMLMediaElement>;
  player: Player;
  poster?: MediaPlayerPoster;
}>;

export const Poster: PosterFC = memoize<PosterFC>(
  ({ className, mediaRef, player, poster }) => {
    return (
      <picture>
        {(() => {
          return Object.entries(poster?.sizes ?? {})
            .filter(([breakpoint]) => breakpoint !== "xl")
            .map(([, size]) => (
              <source
                key={size?.breakpoint}
                media={`(max-width: ${size?.breakpointWidth - 0.05}px)`}
                src={size?.url}
              />
            ));
        })()}

        <img alt="RH" className={className} src={poster?.sizes?.xl?.url} />
      </picture>
    );
  }
);
