export function vendorFunction(
  context: Readonly<any> | null | undefined,
  method: string
): Function | null | undefined {
  if (!context) return () => {};

  const name = `${method[0].toUpperCase()}${method.slice(1)}`;
  const moz = `moz${name}`;
  const ms = `ms${name}`;
  const webkit = `webkit${name}`;

  if (webkit in context) {
    return context[webkit]?.bind(context) as Function;
  } else if (moz in context) {
    return context[moz]?.bind(context) as Function;
  } else if (ms in context) {
    return context[ms]?.bind(context) as Function;
  } else if (method in context) {
    return context[method]?.bind(context) as Function;
  }

  return () => {};
}
