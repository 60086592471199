import { TransitionEvent } from "react";
import { MediaEventHandler } from "../types";
import { updateView } from "./helpers";

export const handleLoaderTransitionEnd: MediaEventHandler<{}, TransitionEvent> =
  function (this, player, details, event) {
    if (Object.is(event.target, event.currentTarget)) {
      if (player.playerState.misc.loaderViewState === "showing") {
        updateView(player, "loader", "visible");
      }

      if (player.playerState.misc.loaderViewState === "hiding") {
        updateView(player, "loader", "hidden");
      }
    }
  };
