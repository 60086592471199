import { MediaPlayerReducer } from "../types";

export const exitFullscreen: MediaPlayerReducer = (
  prevState,
  stateDraft,
  payload
) => {
  document.exitFullscreen();

  stateDraft.fullscreen = false;

  return stateDraft;
};
