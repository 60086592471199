import { MediaPlayerBasicPayload, MediaPlayerReducer } from "../types";
import { setMediaCurrentTime } from "../utils";
import { updateTime } from "./updateTime";

export interface JumpPayload extends MediaPlayerBasicPayload {
  seconds: number;
}

export const jump: MediaPlayerReducer<JumpPayload> = (
  prevState,
  stateDraft,
  payload
) => {
  const { mediaRef, seconds } = payload;

  if (seconds) {
    stateDraft.misc.posterViewState = "hiding";
  } else {
    if (!stateDraft.playing) {
      stateDraft.misc.posterViewState = "showing";
    }
  }

  setMediaCurrentTime(mediaRef?.current, seconds);

  return updateTime(prevState, stateDraft, payload);
};
