import { SyntheticEvent } from "react";
import { MediaEventHandler } from "../types";

export const handleError: MediaEventHandler<{}, SyntheticEvent> = function (
  this,
  player,
  details,
  event
) {
  player.dispatch({
    type: "handleError",
    payload: {
      mediaRef: this
    }
  });
};
