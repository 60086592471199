import {
  MediaPlayerBasicPayload,
  MediaPlayerMiscState,
  MediaPlayerReducer
} from "../types";
import { updateFullscreenState } from "./helpers";

export interface UpdateMiscPayload extends MediaPlayerBasicPayload {
  misc: Partial<MediaPlayerMiscState>;
}

export const updateMisc: MediaPlayerReducer<UpdateMiscPayload> = (
  prevState,
  stateDraft,
  payload
) => {
  const { misc } = payload;

  Object.assign(stateDraft.misc, misc);

  updateFullscreenState(stateDraft, payload);

  return stateDraft;
};
