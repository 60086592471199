import React, { FC } from "react";
import { IconButton } from "utils/material-ui-core";
import { PlayerButtonProps } from "component-media-player/types";

export const PlayerButton: FC<PlayerButtonProps> = props => {
  const { children, childrenAsHTML, ...rest } = props;

  return (
    <IconButton
      {...rest}
      className="rh-mp__playerbutton__root"
      color="secondary"
      component="span"
      size="small"
    >
      {children}
      {childrenAsHTML && (
        <div
          className="rh-mp__playerbutton__injectedroot"
          dangerouslySetInnerHTML={{
            __html: childrenAsHTML
          }}
        />
      )}
    </IconButton>
  );
};

export default PlayerButton;
