import { MediaPlayerReducer } from "../types";

export const updateProgress: MediaPlayerReducer = (
  prevState,
  stateDraft,
  payload
) => {
  const { mediaRef } = payload;

  const time = mediaRef?.current?.currentTime ?? 0;

  let buffered = time;

  if (mediaRef?.current?.buffered?.length) {
    buffered = mediaRef?.current?.buffered?.end(0) ?? 0;
  }

  stateDraft.buffered = buffered;

  return stateDraft;
};
