import {
  MediaPlayerBasicPayload,
  MediaPlayerState
} from "component-media-player/types";
import { vendorProp } from "component-media-player/utils";

export function updateFullscreenState(
  stateDraft: MediaPlayerState,
  payload: MediaPlayerBasicPayload
) {
  const fullscreenElement = vendorProp(document, "fullscreenElement");

  if (!fullscreenElement || !payload.mediaRef?.current) {
    stateDraft.fullscreen = false;

    return;
  }

  const containsTheMedia = Boolean(
    payload.mediaRef.current &&
      fullscreenElement.contains?.(payload.mediaRef.current)
  );

  const isTheMedia = Object.is(fullscreenElement, payload.mediaRef.current);

  stateDraft.fullscreen = containsTheMedia || isTheMedia;
}
