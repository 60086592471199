import { MediaPlayerMiscState, MediaPlayerState } from "../types";

export function newEmptyState(
  partialState: Omit<Partial<MediaPlayerState>, "misc">,
  partialMisc: Partial<MediaPlayerMiscState>
): MediaPlayerState {
  const misc = Object.assign(
    {
      autoHide: true,
      controlsHovered: false,
      controlsViewState: "hidden",
      loaderViewState: "hidden",
      posterViewState: "hidden",
      progressBarHovered: false,
      thumbnailOffset: 0,
      thumbnailTime: 0,
      thumbnailViewState: "hidden",
      volumeSliderViewState: "hidden"
    },
    partialMisc
  );

  return Object.assign(
    {
      buffered: 0,
      duration: 0,
      formattedTime: "0:00",
      fullscreen: false,
      hasAudio: false,
      muted: true,
      playing: false,
      time: 0,
      volume: 1
    },
    partialState,
    { misc }
  );
}
