import { SyntheticEvent } from "react";
import { MediaEventHandler } from "../types";
import { mute } from "./helpers";

export const handleMuteClick: MediaEventHandler<{}, SyntheticEvent> = function (
  this,
  player,
  details,
  event
) {
  mute(this, player);
};
