import { SyntheticEvent } from "react";
import { MediaEventHandler } from "../types";

export const handleProgress: MediaEventHandler<{}, SyntheticEvent> = function (
  this,
  player,
  details,
  event
) {
  if (player.playerProps.controlsType !== "custom") {
    // There is no need to update the progress if the controls are not custom.
    // To save some rendering time.
    return;
  }

  player.dispatch({
    type: "updateProgress",
    payload: {
      mediaRef: this
    }
  });
};
