import { MediaPlayerReducer } from "../types";

export const mute: MediaPlayerReducer = (prevState, stateDraft, payload) => {
  if (!payload.mediaRef?.current) {
    return stateDraft;
  }

  payload.mediaRef.current.muted = true;

  stateDraft.muted = true;

  return stateDraft;
};
