export function isElementInViewport(
  el: HTMLElement | null | undefined,
  win: Window = window
): boolean {
  if (el === null || el === undefined) {
    return false;
  }

  const rect = el.getBoundingClientRect();

  const x1 = Math.max(rect.left, 0);

  const x2 = Math.min(rect.right, win.innerWidth);

  const y1 = Math.max(rect.top, 0);

  const y2 = Math.min(rect.bottom, win.innerHeight);

  return x1 < x2 && y1 < y2;
}
