import analyticsLoader, { withLogs } from "analytics/loader";
import {
  getClosestReactProps,
  ReactPropsMatcher
} from "utils/getClosestReactProps";
import { emitIf, emitWithContext } from "./emitHelpers";

const FALLBACK_MEDIA_URL = "";

const TIME_RATE_WINDOW_LENGTH = 0.25;

type TagName = "ended" | "progress" | "userJump" | "userPause" | "userPlay";

type TagHandler<Data = any> = (
  element: HTMLMediaElement,
  tagName: TagName,
  data?: Data
) => void;

type MediaProps = { source?: { url?: string; streamUrl?: string } };

type MediaStats = {
  currentTime: number;
  duration: number;
  isAutoPlayPending: boolean;

  clearAheadOf: (referenceRate: number) => void;
  clearAll: () => void;

  readonly elapsedTimeRate: number;
  readonly emittedTimeRates: Set<number>;
  readonly progress: number;
  readonly progressWindow: number;
  readonly timeRateWindow: number;
};

function createMediaStats(): MediaStats {
  const emittedTimeRates = new Set<number>();
  let currentTime = 0;
  let duration = 0;

  const stats: MediaStats = {
    isAutoPlayPending: true,

    clearAheadOf(referenceRate) {
      this.emittedTimeRates.forEach(rate => {
        if (rate > referenceRate) {
          this.emittedTimeRates.delete(rate);
        }
      });
    },

    clearAll() {
      this.emittedTimeRates.clear();
    },

    get currentTime() {
      return currentTime;
    },

    set currentTime(value) {
      if (typeof value === "number" && value > 0) {
        currentTime = value;
      }
    },

    get duration() {
      return duration;
    },

    set duration(value) {
      if (typeof value === "number" && value > 0) {
        duration = value;
      }
    },

    get elapsedTimeRate() {
      if (this.duration <= 0) {
        return 0;
      }

      const rate = this.currentTime / this.duration;

      if (rate >= 0.99 && !this.isAutoPlayPending) {
        return 1;
      }
      return rate;
    },

    get emittedTimeRates() {
      return emittedTimeRates;
    },

    get progress() {
      return this.elapsedTimeRate * 100;
    },

    get progressWindow() {
      return this.timeRateWindow * 100;
    },

    get timeRateWindow() {
      return (
        Math.trunc(this.elapsedTimeRate / TIME_RATE_WINDOW_LENGTH) *
        TIME_RATE_WINDOW_LENGTH
      );
    }
  };

  return stats;
}

function getMediaLabel(props: MediaProps): string {
  try {
    return (
      new URL(props?.source?.streamUrl).pathname
        .replace(/\/+$/g, "")
        .split("/")
        .pop() ?? FALLBACK_MEDIA_URL
    );
  } catch {
    return FALLBACK_MEDIA_URL;
  }
}

const matchMediaProps: ReactPropsMatcher<MediaProps> = props => {
  return (
    typeof props["source"] === "object" &&
    typeof props["source"]["streamUrl"] === "string"
  );
};

const loadAnalytics: typeof analyticsLoader = async cb =>
  analyticsLoader(a => cb(withLogs(a)));

const userPlay: TagHandler = element => {
  const props = getClosestReactProps(element, matchMediaProps);

  loadAnalytics(a =>
    a.emitAnalyticsEvent(element, a.EVENTS.VIDEO_PLAY.INT_TYPE, {
      video: {
        event: "video",
        eventCategory: "Video",
        eventAction: "User Play",
        eventLabel: getMediaLabel(props)
      }
    })
  );
};

const userPause: TagHandler = element => {
  const props = getClosestReactProps(element, matchMediaProps);

  loadAnalytics(a =>
    a.emitAnalyticsEvent(element, a.EVENTS.VIDEO_PAUSE.INT_TYPE, {
      video: {
        event: "video",
        eventCategory: "Video",
        eventAction: "User Pause",
        eventLabel: getMediaLabel(props)
      }
    })
  );
};

const userJump: TagHandler<{ currentTime: number }> = (
  element,
  tagName,
  { currentTime }
) => {
  const props = getClosestReactProps(element, matchMediaProps);

  emitWithContext({
    subject: element,

    contextFactory: createMediaStats,

    callback: context => {
      context.currentTime = currentTime;
      context.duration = element.duration;

      loadAnalytics(a =>
        a.emitAnalyticsEvent(element, a?.EVENTS?.VIDEO_JUMP.INT_TYPE, {
          video: {
            event: "video",
            eventCategory: "Video",
            eventAction: "Jump " + context?.progress + "%",
            eventLabel: getMediaLabel(props)
          }
        })
      );
    }
  });
};

const progress: TagHandler = element => {
  emitWithContext({
    subject: element,

    contextFactory: createMediaStats,

    callback: context => {
      context.currentTime = element?.currentTime;
      context.duration = element?.duration;

      context.clearAheadOf(context.elapsedTimeRate);

      emitIf({
        condition: () => element?.autoplay && context?.isAutoPlayPending,

        beforeEmit: () => {
          context.isAutoPlayPending = false;
        },

        emit: () => {
          const props = getClosestReactProps(element, matchMediaProps);

          loadAnalytics(a =>
            a.emitAnalyticsEvent(element, a?.EVENTS.VIDEO_PLAY?.INT_TYPE, {
              video: {
                event: "video",
                eventCategory: "Video",
                eventAction: "Auto Play",
                eventLabel: getMediaLabel(props)
              }
            })
          );
        }
      });

      emitIf({
        condition: () =>
          context?.timeRateWindow > 0 &&
          !context?.emittedTimeRates?.has(context?.timeRateWindow) &&
          !(
            context?.emittedTimeRates?.size == 0 && context?.timeRateWindow == 1
          ),

        beforeEmit: () => {
          context?.emittedTimeRates?.add(context?.timeRateWindow);
        },

        emit: () => {
          const props = getClosestReactProps(element, matchMediaProps);

          loadAnalytics(a =>
            a.emitAnalyticsEvent(element, a?.EVENTS?.VIDEO_PROGRESS?.INT_TYPE, {
              video: {
                event: "video",
                eventCategory: "Video",
                eventAction: context?.progressWindow + "% Watched",
                eventLabel: getMediaLabel(props)
              }
            })
          );
        }
      });
    }
  });
};

const ended: TagHandler = element => {
  emitWithContext({
    subject: element,

    contextFactory: createMediaStats,

    callback: context => {
      context.clearAll();
    }
  });
};

const handlers: Record<TagName, TagHandler> = {
  ended,
  progress,
  userJump,
  userPause,
  userPlay
};

export function fireMediaEventHandler<Data>(
  element: HTMLMediaElement | null | undefined,
  tagName: TagName,
  data?: Data
) {
  if (!element) {
    return;
  }

  handlers[tagName]?.(element, tagName, data);
}
