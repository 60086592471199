import { SyntheticEvent } from "react";
import { fireMediaEventHandler } from "utils/analytics/fireMediaEventHandler";
import { MediaEventHandler } from "../types";

export const handleTimeUpdate: MediaEventHandler<{}, SyntheticEvent> =
  function (this, player, details, event) {
    fireMediaEventHandler(this.current, "progress");

    player.dispatch({
      type: "updateTime",
      payload: {
        mediaRef: this
      }
    });
  };
