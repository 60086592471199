export function vendorProp<T extends Object, Prop extends keyof T, Value = any>(
  context: Readonly<T> | null | undefined,
  prop: Prop,
  fallback?: Value | null
): Value | null | undefined {
  if (!context) {
    return fallback;
  }

  const name = `${(prop as string)[0].toUpperCase()}${(prop as string).slice(
    1
  )}`;
  const moz = `moz${name}`;
  const ms = `ms${name}`;
  const webkit = `webkit${name}`;

  if (webkit in context) {
    return (context as any)[webkit];
  } else if (moz in context) {
    return (context as any)[moz];
  } else if (ms in context) {
    return (context as any)[ms];
  } else if (prop in context) {
    return (context as any)[prop as string];
  }

  return fallback;
}
